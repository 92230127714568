var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('app-layout',[(_vm.showFormFilter)?_c('form-filter-popup',{attrs:{"forms":_vm.rowData,"form-filter":_vm.formFilter,"form-categories":_vm.formCategories,"form-artificial-lift-types":_vm.formArtificialLiftTypes},on:{"reset-filtering":_vm.resetFiltering,"apply-filtering":_vm.applyFiltering,"close-popup":function($event){_vm.showFormFilter = false}}}):_vm._e(),(_vm.showFormDetail)?_c('form-detail-popup',{attrs:{"form-artificial-lift-types":_vm.formArtificialLiftTypes,"form":_vm.form,"form-titles":_vm.allFormsTitles,"form-categories":_vm.formCategories},on:{"close-popup":_vm.closeFormPopup,"save-popup":_vm.saveFormPopup}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.dataLoading),expression:"!dataLoading"}],staticClass:"h-full flex flex-col"},[_c('div',{staticClass:"ml-0 md:ml-6 -mt-16",staticStyle:{}},[_c('div',{staticClass:"w-full overflow-hidden pl-2.5"},[_c('p',{staticClass:"font-bold my-4 ml-4 cursor-pointer text-2xl text-white mb-5",on:{"click":function($event){return _vm.$router.push({ name: 'ControlPanel' })}}},[_c('button',{staticClass:"float-left -ml-5 text-sm rounded-lg shadow-lg text-gray001 mt-1.5 border-gray001"},[_c('span',{staticClass:"material-icons round-lg cursor-pointer inline-block align-middle leading-none"},[_vm._v(" arrow_back_ios ")])]),_vm._v(" Forms ")])])]),_c('div',{staticClass:"grid grid-cols-6 gap-5 mt-5 mb-3"},[_c('div',{staticClass:"col-span-1 text-right"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            theme: 'tasq-tooltip',
            content: 'Add Form',
          }),expression:"{\n            theme: 'tasq-tooltip',\n            content: 'Add Form',\n          }",modifiers:{"bottom":true}}],staticClass:"inline-block rounded text-center pt-2",on:{"click":function($event){_vm.showFormDetail = true}}},[_c('mdicon',{staticClass:"cursor-pointer text-tasqBase100",attrs:{"name":"plus-circle","size":"24"},on:{"click":function($event){_vm.showFormDetail = true}}})],1)]),_c('div',{staticClass:"col-span-4"},[_c('t-input',{staticClass:"border-solid border border-white border-opacity-10 inline-block w-full text-white bg-blue026 border border-white011 rounded",attrs:{"id":"search-input","placeholder":"Search for form or category..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',{staticClass:"col-span-1 text-left"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            theme: 'tasq-tooltip',
            content: 'Filter Forms',
          }),expression:"{\n            theme: 'tasq-tooltip',\n            content: 'Filter Forms',\n          }",modifiers:{"bottom":true}}],staticClass:"inline-block rounded text-center pt-2",on:{"click":function($event){_vm.showFormFilter = true}}},[_c('mdicon',{staticClass:"cursor-pointer text-white",attrs:{"name":"filter","size":"24"}})],1)])]),_c('div',{staticClass:"mt-5 min-h-0 flex-grow"},[_c('ag-grid-vue',{staticClass:"h-full ag-theme-alpine action-builder",attrs:{"column-defs":_vm.columnDefs,"borders":false,"row-data":_vm.filteredFormData,"context":_vm.context,"default-col-def":_vm.defaultColDef,"get-row-node-id":_vm.getRowNodeId,"single-click-edit":true,"suppress-row-transform":true,"overlay-no-rows-template":``},on:{"onRowClicked":_vm.cellClick,"grid-ready":_vm.onGridReady}})],1),(_vm.deleteFormPopup)?_c('confirm-popup',{attrs:{"information":"Deleting this form will also delete any attached data.","question":" Delete this form?"},on:{"confirm-popup":_vm.confirmDeleteForm,"close-popup":function($event){_vm.deleteFormPopup = false;
        _vm.formID = '';}}}):_vm._e()],1),_c('tasq-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataLoading),expression:"dataLoading"}]})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }